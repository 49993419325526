import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getMessage } from '../modules/messages'
import { getReceiver } from '../modules/receivers'
import Message from '../components/message'
import '../styles/message.scss'
import AppDescription from '../components/app-description'

class MessageView extends Component {
  componentDidMount() {
    const { message, match } = this.props
    console.log('componentDidMount()')

    if (!message.data) {
      if (match.params.id) this.props.onGetMessage(match.params.id)
      if (match.params.recId) this.props.onGetReceiver(match.params.recId)
    }
  }

  componentDidUpdate(newProps) {
    const { message } = this.props
    console.log('componentDidUpdate(newProps)')
    if (this.props !== newProps) {
      if (!message.loading && !message.data) {
        this.props.history.push(`/`)
      }
    }
  }

  render() {
    const { message, match, receiver } = this.props

    // Null checking
    if (!message.data) return null
    if (match.params.recId && !receiver) return null

    return (
      <div className="default message-view">
        <Message message={message.data} receiver={receiver} />
        <AppDescription />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    message: state.message,
    receiver: state.receiver
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onGetMessage: id => {
      dispatch(getMessage(id))
    },
    onGetReceiver: id => {
      dispatch(getReceiver(id))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MessageView)
