import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getStories } from '../modules/stories'
import Story from '../components/story'
import { isEmpty } from '../utils'
import find from 'lodash/find'
import '../styles/story.scss'
import AppDescription from '../components/app-description'

class StoryView extends Component {
  constructor(props) {
    super(props)

    this.state = {
      visibleStory: null
    }
  }

  componentDidMount() {
    if (isEmpty(this.props.stories)) {
      this.props.onGetStories()
    } else {
      this._selectStory()
    }
  }

  componentDidUpdate(newProps) {
    if (this.props !== newProps) {
      this._selectStory()
    }
  }

  _selectStory = () => {
    const thisStory = find(this.props.stories, [
      '_id',
      this.props.match.params.id
    ])

    // Redirect if no story
    if (thisStory) {
      this.setState({
        visibleStory: thisStory
      })
    } else {
      this.props.history.push(`/stories`)
    }
  }

  render() {
    const { visibleStory } = this.state

    if (isEmpty(visibleStory)) {
      return null
    }

    return (
      <div className="default story-view">
        <Story item={visibleStory} isSingleStory={true} />
        <AppDescription />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    stories: state.stories
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onGetStories: () => {
      dispatch(getStories())
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StoryView)
