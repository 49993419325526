import React, { Component } from 'react'
import { connect } from 'react-redux'
import { requestResetPassword } from '../modules/reset-password'
import { isEmailValid } from '../utils'
import '../styles/support.scss'

class Support extends Component {
  constructor(props) {
    super(props)
    this.state = {
      emailAddress: '',
      message: {
        type: '',
        text: ''
      }
    }
  }

  componentDidUpdate(newProps) {
    if (this.props !== newProps) {
      this.checkStatus()
    }
  }

  render() {
    const { message } = this.state

    return (
      <div className="default support">
        <h1>Contact</h1>
        <div className="about">
          <p>
            Unspoken was designed and built by Adam and Victoria, a happily
            married designer/therapist couple in sunny Palo Alto, CA.
          </p>
        </div>

        <div className="reach-out">
          <h3>Say hello!</h3>
          <p>
            <a href="mailto:victoria@unspokenapp.com">
              victoria@unspokenapp.com
            </a>
          </p>
          <p>
            <a href="mailto:adam@unspokenapp.com">adam@unspokenapp.com</a>
          </p>
          <span className="divider" />
        </div>

        <div className="forgot-pass">
          <h3>Forgot your password?</h3>

          <div className="input-view">
            <form onSubmit={this._submitForm}>
              <button type="submit">Submit</button>
              <input
                type="text"
                placeholder="Email address"
                value={this.state.emailAddress}
                onChange={this._handleChange}
              />
            </form>
          </div>

          {message.text && (
            <div className="message-view">
              <p className={message.type}>{message.text}</p>
            </div>
          )}
        </div>
      </div>
    )
  }

  checkStatus = () => {
    const { resetPassword } = this.props

    if (resetPassword.requested === true) {
      this._showMessage(
        'success',
        'Success! Please check your inbox to reset your password. It may take a few minutes to arrive.'
      )
    } else if (resetPassword.requested === false) {
      this._showMessage('error', 'That email was not found. Please try again.')
    }
  }

  _handleChange = e => {
    this.setState({ emailAddress: e.target.value })
  }

  _submitForm = e => {
    e.preventDefault()

    // Validate
    if (!isEmailValid(this.state.emailAddress)) {
      this._showMessage('error', 'Please enter a valid email :(')
      return
    }

    // Make API call
    this.props.onRequestResetPassword(this.state.emailAddress)
  }

  _showMessage = (type, text) => {
    this.setState({
      emailAddress: '',
      message: {
        type,
        text
      }
    })
  }
}

const mapStateToProps = state => {
  return {
    resetPassword: state.resetPassword
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onRequestResetPassword: email => {
      dispatch(requestResetPassword(email))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Support)
