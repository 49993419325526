import React from 'react'

const About = () => (
  <div className="default secondary">
    <h1>Why I made Unspoken.</h1>
    <p>
      In May of 2016, I was out to dinner with my then-girlfriend/now-wife,
      Victoria, in Palo Alto. It wasn't anything out of the ordinary, maybe a
      Taco Tuesday or a Wine Wednesday. We were participating in one of our
      favorite hobbies that we've enjoyed since our first date: people-watching
      and ear-hustling (a more adult-ly and James Bond-y word for
      "eavesdropping").
    </p>
    <p>
      We got to talking and a topic came up that isn't uncommon: J. Paul
      DeWoody, Victoria's father who passed away suddenly in August of 2014. She
      had a wonderful and loving relationship with her dad.{' '}
      <b>
        The kind of love that every father probably dreams of and strives for.
      </b>{' '}
      He was her go-to person for everything and was always able to put a smile
      on her face. She's frequently reciting "Paul-isms" that seemingly exist
      for any moment and occasion. They shared a love that I hope to share with
      my daughter one day (If my mom is reading this, I know you want
      grand-babies asap, but cool it a bit will ya?). I was never able to meet
      him. I met Victoria a few months after he passed.
    </p>
    <p>At one point Victoria looked at me and said:</p>
    <p className="quote">
      "Sometimes I just wish I could call my dad and leave a message."
    </p>
    <p>Being the tech-y that I am, I responded:</p>
    <p className="quote">"Well... we could make that."</p>
    <p>
      A spark! We would spend a large chunk of the next few hours and days
      brainstorming what this experience could be like and researching if this
      already exists. No, it's not a voice journal or a voice diary. We want to
      simulate a phone call, with a ring, hear their voicemail greeting, and a
      beep, and then record whatever we want to say, and be able to save it...{' '}
      <b>like we're actually leaving a voicemail.</b>
    </p>
    <p>
      Why is voice important? Victoria is the best therapist in the world (I may
      be a bit biased) and knows the cathartic value of voice versus written
      text. Saying what you're feeling out loud, and then listening to yourself
      seconds/hours/days later can be an important part of retrospection and
      healing.
    </p>
    <p>
      The idea was born...and it turns out Victoria is a great muse (I even
      mentioned this in my wedding vows). I was <b>extremely</b> motivated.
      Knowing that she would find value in this tool kept me up late, woke me up
      early, and made all of the hard work seem minuscule in comparison to the
      potential outcome.
    </p>
    <p>
      She's really the only user that I cared about (still the one I care most
      for) and all of Unspokens features are inspired by her needs and what she
      knows about the various human emotional processes.
    </p>
    <p>
      Nine months later, Unspoken was born. We released it on the App Store and
      had a quiet release party with champagne (AKA Trader Joe's sparkling wine)
      and one of Victoria's close therapist friends who was instrumental in the
      process.
    </p>
    <p>[INSERT PIC OF US DRINKING CHAMPAGNE]</p>
    <p>
      Since its release, we've heard from many people using Unspoken as a
      successful support tool in their lives. Some use it to heal, some to
      capture happy and exciting moments, and some others use it in heartwarming
      ways we never expected.{' '}
      <b>
        Already an incredibly fulfilling milestone for both Victoria and me.
      </b>
    </p>
    <p>
      Our hope for Unspoken is that it will provide support to those in need,
      connect people in joy, and be a platform to experience how human emotions
      make us more alike than we realize.
    </p>
    <p>
      We wish you or someone you know finds as much value as Victoria and others
      have.
    </p>
    <span className="sigs" />
    <p className="ps">
      P.S. Please let us know if you have any suggestions to improve the
      experience. <a href="mailto:adam@unspokenapp.com">Send me an email</a> and
      let's chat!
    </p>
  </div>
)

export default About
