import React from 'react'
import { Link } from 'react-router-dom'
import '../styles/footer.scss'

const Footer = () => (
  <div id="footer">
    <div className="inner">
      <p>
        <Link to="/">Home</Link>
        &middot;
        {/* <Link to="/support">Support</Link>
        &middot; */}
        <Link to="/contact">Contact</Link>
        &middot;
        <Link to="/privacy">Privacy policy</Link>
        &middot;
        <Link to="/tos">Terms and conditions</Link>
        &middot;
        <span>© 2019 Adam Storr</span>
      </p>
    </div>
    <div className="download-link">
      <a
        href="https://itunes.apple.com/us/app/unspoken-voice-mailbox-for-unexpressed-emotions/id1134416200?mt=8"
        target="_blank"
        rel="noopener noreferrer">
        Download
      </a>
    </div>
  </div>
)

export default Footer
