import React from 'react'
import '../styles/app-description.scss'

const AppDescription = props => (
  <div className="app-description">
    <h2>What is Unspoken?</h2>
    <p>
      Unspoken is a voice mailbox for the unspoken stories of the mind.{' '}
      <strong>A person in grief</strong> can call and leave a story for someone
      they miss. <strong>New families</strong> can record messages for their
      babies to listen to when they grow older. <strong>Those angry</strong> can
      release that bottled up resentment, and <strong>those dying</strong> can
      record what they wish they had the power to say in real life.
    </p>
    <p>
      Unspoken acts as a holding place for emotions and allows users to express
      and record their feelings. The result can be cathartic, as things unspoken
      are released, and what you want to say… is said.
    </p>
  </div>
)

export default AppDescription
