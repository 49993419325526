import React from 'react'
import { Route } from 'react-router-dom'

import Nav from '../components/nav'
import Footer from '../components/footer'

import Home from './home'
import About from './about'
import StoryListView from './story-list'
import StoryView from './story-view'
import MessageView from './message-view'
import Help from './help'
import Supporters from './supporters'
import Privacy from './privacy'
import Tos from './tos'
import Support from './support'
import ResetPassword from './reset-password'
import Security from './security'
import OurStory from './our-story'
import Contact from './contact'

const App = () => (
  <div>
    <Nav />
    <main>
      <Route exact path="/" component={Home} />
      <Route exact path="/about" component={About} />
      <Route exact path="/security" component={Security} />
      <Route exact path="/our-story" component={OurStory} />
      <Route exact path="/stories" component={StoryListView} />
      <Route path="/stories/:id" component={StoryView} />
      <Route exact path="/message/:id" component={MessageView} />
      <Route exact path="/message/:id/:recId" component={MessageView} />
      <Route exact path="/help" component={Help} />
      <Route exact path="/supporters" component={Supporters} />
      <Route exact path="/privacy" component={Privacy} />
      <Route exact path="/tos" component={Tos} />
      <Route exact path="/contact" component={Contact} />
      <Route exact path="/support" component={Support} />
      <Route path="/reset/:id" component={ResetPassword} />
    </main>
    <Footer />
  </div>
)

export default App
