import React, { Component } from 'react'
import Waveform from './waveform'
import { isEmpty } from '../utils'
import * as classNames from 'classnames'
import Moment from 'react-moment'
import '../styles/story.scss'

class Message extends Component {
  constructor(props) {
    super(props)

    this.state = {
      imageLoaded: false
    }
  }

  componentDidMount() {
    this.setMessageState()
  }

  componentDidUpdate(newProps) {
    if (this.props !== newProps) {
      this.setMessageState()
    }
  }

  setMessageState = () => {
    const { message } = this.props
    if (!message) return null

    this.setState({
      imageURL:
        process.env.REACT_APP_S3_URL +
        '/user/' +
        message.createdBy._id +
        '/images/' +
        message.createdFor.photo,
      audioURL:
        process.env.REACT_APP_S3_URL +
        '/user/' +
        message.createdBy._id +
        '/audio/' +
        message.media
    })
  }

  render() {
    const { message } = this.props
    const { audioURL, imageURL } = this.state

    return (
      <div className="message-card">
        <div className="player-view">
          <div className="content-view">
            {this.renderReceiver()}

            {audioURL && (
              <div className="waveform-wrapper">
                <Waveform src={audioURL} />
              </div>
            )}

            <div className="message-details">
              <p className="note">{message.content}</p>
              <div className="divider" />
              {this.renderCreator()}
            </div>
          </div>
          {message.createdFor.photo && (
            <div className="image-view">
              <img
                alt=""
                src={imageURL}
                onLoad={this._handleImageLoaded}
                className={classNames({ '-loaded': this.state.imageLoaded })}
              />
            </div>
          )}
        </div>
      </div>
    )
  }

  renderReceiver = () => {
    const { message, receiver } = this.props
    const { imageURL } = this.state

    return (
      <div className="receiver-info">
        <div className="receiver-info-inside">
          {message.createdFor.photo && (
            <div className="photo">
              <img
                alt=""
                src={imageURL}
                className={classNames({
                  '-loaded': this.state.imageLoaded
                })}
              />
            </div>
          )}
          <p className="name">{message.createdFor.name},</p>
        </div>
        {receiver && receiver.note && (
          <div className="receiver-note">
            <p>{receiver.note}</p>
          </div>
        )}
      </div>
    )
  }

  renderCreator = () => {
    const { message, receiver } = this.props

    return (
      <div className="creator">
        {message.createdBy.email && (
          <p className="name">From, {message.createdBy.email}</p>
        )}
        {!isEmpty(receiver) && (
          <div className="date-view">
            <p className="date">
              Created:{' '}
              <Moment format="MMM, Do YYYY">{message.createdAt}</Moment>
              <span>&middot;</span> Sent:{' '}
              <Moment format="MMM, Do YYYY">{receiver.date}</Moment>
            </p>
          </div>
        )}
        {isEmpty(receiver) && (
          <div className="date-view">
            <p className="date">{message.createdAt}</p>
          </div>
        )}
      </div>
    )
  }

  _handleImageLoaded = () => {
    this.setState({
      imageLoaded: true
    })
  }
}

export default Message
