import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import * as classNames from 'classnames'
import '../styles/nav.scss'

const Nav = withRouter(props => (
  <header className={classNames({ home: props.location.pathname === '/' })}>
    <div className="logo-view">
      <Link to="/">
        <div className="unspoken-logo" />
      </Link>
    </div>
    <div className="links">
      <Link
        to="/about"
        className={classNames({
          selected: props.location.pathname === '/about'
        })}>
        About
      </Link>
      <Link
        to="/stories"
        className={classNames({
          selected: props.location.pathname === '/stories'
        })}>
        Stories
      </Link>
      {/* <Link
        to="/security"
        className={classNames({
          selected: props.location.pathname === '/security'
        })}>
        Security
      </Link> */}
      <Link
        to="/help"
        className={classNames({
          selected: props.location.pathname === '/help'
        })}>
        Help us
      </Link>
      <Link
        to="/support"
        className={classNames({
          selected: props.location.pathname === '/support'
        })}>
        Contact
      </Link>
    </div>
  </header>
))

export default Nav
