import React from 'react'
import { Link } from 'react-router-dom'
import '../styles/secondary.scss'

const Supporters = () => (
  <div className="default secondary supporters">
    <h1>Our Supporters.</h1>
    <p>
      There are certain people I must mention as crucial pieces to Unspokens
      existence:
    </p>
    <div className="early-supporters">
      <div className="supporter">
        <p>
          <span role="img" aria-label="emoji">
            💏
          </span>{' '}
          Victoria
        </p>
        <span>
          There's not <Link to="/about">much more to say</Link> about Victoria
          other than: she's the only reason why this exists.
        </span>
      </div>
      <div className="supporter">
        <p>
          <span role="img" aria-label="emoji">
            👪
          </span>{' '}
          My parents, Gina and Steve
        </p>
        <span>
          I'm very lucky to have grown up in the loving environment my parents
          created. They would sacrifice anything to show their love and support
          for others.
        </span>
      </div>
      <div className="supporter">
        <p>
          <span role="img" aria-label="emoji">
            💁🏼
          </span>{' '}
          Our friend Nico
        </p>
        <span>
          So so helpful during our ideation and testing process. A lot of
          features wouldn't have been as well thought out without her!
        </span>
      </div>
      <div className="supporter">
        <p>
          <span role="img" aria-label="emoji">
            ❤️
          </span>{' '}
          My Aunt Pam and Uncle Tom
        </p>
        <span>One of my forever role models for a loving relationship.</span>
      </div>
    </div>

    <div className="continued-supporters">
      <p>
        ...and those who continue to make Unspoken{' '}
        <Link to="/help">free for everyone</Link>:
      </p>
      <div className="supporter">
        <p>
          <span role="img" aria-label="emoji">
            😎
          </span>{' '}
          Brian
        </p>
        <p>
          <span role="img" aria-label="emoji">
            🙌
          </span>{' '}
          Mackenzi
        </p>
        <p>
          <span role="img" aria-label="emoji">
            💁🏼
          </span>{' '}
          Nico
        </p>
        <p>
          <span role="img" aria-label="emoji">
            🤠
          </span>{' '}
          Andy
        </p>
        <p>
          <span role="img" aria-label="emoji">
            🤘🏼
          </span>{' '}
          Matt
        </p>
      </div>
    </div>
  </div>
)

export default Supporters
