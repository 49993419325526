import axios from 'axios'

export const REQUEST_RESET_PASSWORD = 'REQUEST_RESET_PASSWORD'
export const CHECK_TOKEN = 'CHECK_TOKEN'
export const RESET_PASSWORD = 'RESET_PASSWORD'

const initialState = {
  loading: true,
  requested: null,
  valid: null,
  completed: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_RESET_PASSWORD:
      return {
        ...state,
        loading: false,
        requested: action.payload
      }

    case CHECK_TOKEN:
      return {
        ...state,
        loading: false,
        valid: action.payload
      }

    case RESET_PASSWORD:
      return {
        ...state,
        loading: false,
        completed: action.payload
      }

    default:
      return state
  }
}

export const requestResetPassword = email => {
  return dispatch => {
    return axios
      .post(`${process.env.REACT_APP_API_URL}/user/forgot-pass`, { email })
      .then(response => {
        dispatch(resetPasswordRequested(true))
      })
      .catch(error => {
        dispatch(resetPasswordRequested(false))
      })
  }
}

export const resetPasswordRequested = isSuccessful => {
  return {
    type: REQUEST_RESET_PASSWORD,
    payload: isSuccessful
  }
}

export const checkToken = token => {
  return dispatch => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/user/reset-pass/${token}`, {
        token
      })
      .then(response => {
        dispatch(collectCheckToken(true))
      })
      .catch(error => {
        dispatch(collectCheckToken(false))
      })
  }
}

export const collectCheckToken = isSuccessful => {
  return {
    type: CHECK_TOKEN,
    payload: isSuccessful
  }
}

export const resetPassword = (token, password) => {
  return dispatch => {
    return axios
      .post(`${process.env.REACT_APP_API_URL}/user/reset-pass/${token}`, {
        password
      })
      .then(response => {
        dispatch(resetPasswordComplete(true))
      })
      .catch(error => {
        dispatch(resetPasswordComplete(false))
      })
  }
}

export const resetPasswordComplete = isSuccessful => {
  return {
    type: RESET_PASSWORD,
    payload: isSuccessful
  }
}
