export function isEmailValid(email) {
  let reg = '/^w+([.-]?w+)*@w+([.-]?w+)*(.w{2,3})+$/'
  if (reg.test(email) === false) {
    return false
  } else {
    return true
  }
}

export function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false
  }
  return true
}
