import axios from 'axios'

export const GET_RECEIVER = 'GET_RECEIVER'

export default (state = {}, action) => {
  switch (action.type) {
    case GET_RECEIVER:
      return action.payload

    default:
      return state
  }
}

export const getReceiver = id => {
  return dispatch => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/receiver/${id}`)
      .then(response => {
        dispatch(collectReceiver(response))
      })
      .catch(error => {
        throw error
      })
  }
}

export const collectReceiver = response => {
  return {
    type: GET_RECEIVER,
    payload: response.data
  }
}
