import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import WaveSurfer from 'wavesurfer.js'
import * as classNames from 'classnames'

class Waveform extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoaded: false,
      playing: false,
      length: undefined,
      moment: '0',
      remaining: undefined
    }
  }

  componentDidMount() {
    this.$el = ReactDOM.findDOMNode(this)
    this.$waveform = this.$el.querySelector('.waveform')
    this.wavesurfer = WaveSurfer.create({
      container: this.$waveform,
      waveColor: 'rgba(255, 255, 255, 0.25)',
      progressColor: 'rgba(255, 255, 255, 0.5)',
      normalize: true,
      hideScrollbar: true,
      responsive: true,
      height: 250,
      barWidth: 2,
      cursorColor: 'rgba(255, 255, 255, 0.25)'
    })

    this.wavesurfer.load(this.props.src)
    this.wavesurfer.on('ready', this._loadWaveform)
    this.wavesurfer.on('interaction', this._interactedWith)
    this.wavesurfer.on('finish', () => this.setState({ playing: false }))
  }

  _loadWaveform = () => {
    this.setState({
      isLoaded: true,
      length: Math.floor(this.wavesurfer.getDuration()).toString()
    })

    this._interactedWith()
  }

  _interactedWith = () => {
    setInterval(() => {
      this.setState({
        moment: Math.floor(this.wavesurfer.getCurrentTime()).toString(),
        remaining: Math.floor(this.state.length - this.state.moment).toString()
      })
    }, 1000)
  }

  render() {
    const { playing, remaining, isLoaded } = this.state

    return (
      <div
        className={classNames('wave-control-wrap', {
          '-is-loaded': isLoaded
        })}>
        <button
          className={classNames({
            'play-btn': !playing,
            'pause-btn': playing
          })}
          onClick={this._playPause}
        />
        <span className="time-remaining">
          <span>{this.renderTimeLeft(remaining)}</span>
        </span>
        <div className="waveform" />
      </div>
    )
  }

  _playPause = () => {
    if (this.wavesurfer.backend.ac.state !== 'running') {
      this.wavesurfer.backend.ac.resume()
    }
    this.wavesurfer.playPause()
    this.setState({ playing: !this.state.playing })
  }

  renderTimeLeft = str => {
    var sec_num = parseInt(str, 10),
      hours = Math.floor(sec_num / 3600),
      minutes = Math.floor((sec_num - hours * 3600) / 60),
      seconds = sec_num - hours * 3600 - minutes * 60

    if (hours < 10) {
      hours = '0' + hours
    }
    if (minutes < 10) {
      minutes = '0' + minutes
    }
    if (seconds < 10) {
      seconds = '0' + seconds
    }

    var time = minutes + ':' + seconds

    return time
  }
}

Waveform.defaultProps = {
  src: ''
}

export default Waveform
