import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import getStories from './stories'
import getMessage from './messages'
import getReceiver from './receivers'
import resetPassword from './reset-password'

export default history =>
  combineReducers({
    router: connectRouter(history),
    resetPassword,
    stories: getStories,
    message: getMessage,
    receiver: getReceiver
  })
