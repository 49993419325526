import React, { Component } from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import * as classNames from 'classnames'
import '../styles/carousel.scss'

class CarouselView extends Component {
  state = {
    visibleSlide: 0,
    carouselData: [
      {
        which: 'loss',
        title: 'Lost someone?',
        desc: 'Call and leave a message for whoever you miss.'
      },
      {
        which: 'death',
        title: "What's left unspoken?",
        desc:
          'Those dying can record their final thoughts and wishes that have been left unsaid.'
      },
      {
        which: 'anger',
        title: 'Need to vent?',
        desc: 'Let it out! Discharge negative emotions in a healthy way.'
      },
      {
        which: 'memories',
        title: 'New addition?',
        desc: 'Record messages for your baby to listen to when they’re older.'
      }
    ]
  }

  componentDidMount() {
    this.rotateCarousel = setInterval(() => {
      var nextSlide =
        this.state.visibleSlide + 1 < this.state.carouselData.length
          ? this.state.visibleSlide + 1
          : 0

      this.setState({
        visibleSlide: nextSlide
      })
    }, 4000)
  }

  componentWillUnmount() {
    // Clear rotation
    clearInterval(this.rotateCarousel)
  }

  changeSlide = location => {
    // Clear rotation
    clearInterval(this.rotateCarousel)

    // Set visible slide
    this.setState({
      visibleSlide: location
    })
  }

  render() {
    const { carouselData, visibleSlide } = this.state

    const carouselItems = carouselData.map((item, key) => (
      <div
        key={key}
        className={classNames('item', item.which, {
          selected: visibleSlide === key
        })}>
        <h2>{item.title}</h2>
        <p>{item.desc}</p>
      </div>
    ))

    const carouselNav = carouselData.map((item, key) => (
      <div
        key={key}
        rel={item.which}
        className={classNames('item', {
          selected: visibleSlide === key
        })}>
        <div className="link" onClick={() => this.changeSlide(key)}>
          {item.which}
        </div>
      </div>
    ))

    return (
      <div className="carousel-view">
        <div className="carousel-inner">
          <div className="carousel-content">{carouselItems}</div>
          <div className="carousel-nav">{carouselNav}</div>
        </div>
        <div className="carousel-bkg">
          <ReactCSSTransitionGroup
            transitionName={'fade'}
            transitionEnterTimeout={500}
            transitionLeaveTimeout={500}
            component="div"
            className="carousel__slide"
            transitionAppear={false}
            transitionAppearTimeout={1000}>
            {carouselData.map((item, key) => (
              <div
                key={key}
                className={classNames('slide', item.which, {
                  selected: visibleSlide === key
                })}
              />
            ))}
          </ReactCSSTransitionGroup>
        </div>
      </div>
    )
  }
}

export default CarouselView
