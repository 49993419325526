import React from 'react'
import { Link } from 'react-router-dom'

// Styles
import '../styles/home.scss'
import CarouselView from '../components/carousel'

const Home = props => (
  <div>
    <div id="section-top">
      <div id="inner" className="loss">
        <CarouselView />
      </div>
    </div>
    <div id="section-bottom">
      <div className="inner">
        <div id="download-view">
          <a
            href="https://itunes.apple.com/app/id1134416200"
            target="_blank"
            rel="noopener noreferrer">
            <span>Download on the App Store</span>
          </a>
        </div>
        <div id="main-description">
          <h2>What is Unspoken?</h2>
          <p>
            Unspoken is a voice mailbox for the unspoken stories of the mind.{' '}
            <strong>A person in grief</strong> can call and leave a message for
            someone they miss. <strong>New families</strong> can record messages
            for their babies to listen to when they grow older.{' '}
            <strong>Those angry</strong> can release that bottled up resentment,
            and <strong>those dying</strong> can record what they wish they had
            the power to say in real life.
          </p>
          <p>
            Unspoken acts as a holding place for emotions and allows users to
            express and record their feelings. The result can be cathartic, as
            things unspoken are released, and what you want to say… is said.
          </p>
        </div>
        <div id="our-note">
          <span className="hearts" />
          <p>
            Our hope is that Unspoken will provide support to those in need,
            connect people in joy, and be a platform to experience how human
            emotions make us more alike than we realize.
          </p>
          <p>Enjoy,</p>
          <span className="sigs" />
          <p className="attr">CREATORS OF UNSPOKEN</p>
        </div>
        <div id="more-info">
          <Link to="/supporters" className="why-i-made-it section">
            <h2>Why I made Unspoken.</h2>
            <p>
              …turns out Victoria is a great muse (I even mentioned this in my
              wedding vows). I was extremely motivated. Knowing that she would
              find value in this tool kept me up late, woke...
            </p>
            <span>READ MORE</span>
          </Link>
          <Link to="/help" className="we-need-your-help section">
            <h2>We need your help.</h2>
            <p>
              Since we launched, we've heard from many people using Unspoken as
              a successful support tool. Some use it to heal, some to capture
              happy and exciting moments, and some others use it…
            </p>
            <span>READ MORE</span>
          </Link>
        </div>
      </div>
      <div className="curved-bkg" />
    </div>
  </div>
)

export default Home
