import axios from 'axios'

export const GET_STORIES = 'GET_STORIES'

export default (state = {}, action) => {
  switch (action.type) {
    case GET_STORIES:
      return action.payload

    default:
      return state
  }
}

export const getStories = () => {
  return dispatch => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/stories/new`)
      .then(response => {
        dispatch(collectStories(response.data))
      })
      .catch(error => {
        dispatch(collectStories(undefined))
      })
  }
}

export const collectStories = data => {
  return {
    type: GET_STORIES,
    payload: data
  }
}
