import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getStories } from '../modules/stories'
import '../styles/story.scss'
import { isEmpty } from '../utils'
import Story from '../components/story'

class StoryListView extends Component {
  componentDidMount() {
    this.props.onGetStories()
  }

  render() {
    return <div className="default story-view">{this.renderStoryList()}</div>
  }

  renderStoryList() {
    const { stories } = this.props

    if (isEmpty(stories)) {
      return null
    }

    return (
      <div className="story-list secondary">
        <div className="story-intro">
          <h1>Unspoken Stories</h1>
          <p>
            These stories are shared anonymously by the Unspoken community.
            Share your own by choosing the 'Share anonymously' option after
            recording a message.
          </p>
        </div>
        {stories.map((item, key) => (
          <Story key={key} item={item} isSingleStory={false} />
        ))}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    stories: state.stories
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onGetStories: () => {
      dispatch(getStories())
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StoryListView)
