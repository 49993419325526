import React from 'react'
import '../styles/secondary.scss'

const Contact = () => (
  <div className="default secondary">
    <h1>Contact</h1>
    <p>Something goes here...</p>
  </div>
)

export default Contact
