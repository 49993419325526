import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Waveform from './waveform'
import * as classNames from 'classnames'
import '../styles/story.scss'

class Story extends Component {
  constructor(props) {
    super(props)

    this.state = {
      imageLoaded: false
    }
  }

  render() {
    const { item, isSingleStory } = this.props
    const imageURL =
      'https://unspoken-prod.s3.amazonaws.com/app/stories/images/' + item.photo
    const audioURL =
      'https://unspoken-prod.s3.amazonaws.com/app/stories/audio/' + item.media

    return (
      <div className="story-card">
        <div className="player-view-wrapper">
          <div className="player-view-title">
            {isSingleStory && (
              <Link to="/stories" className="all-stories">
                Unspoken stories
              </Link>
            )}

            {item.title && (
              <Link to={`/stories/${item._id}`} className="player-title">
                {item.title}
              </Link>
            )}
          </div>

          {item.commentary && (
            <div className="player-view-commentary">{item.commentary}</div>
          )}

          <div className="player-view">
            <div className="content-view">
              {audioURL && (
                <div className="waveform-wrapper">
                  <Waveform src={audioURL} />
                </div>
              )}
              <div className="story-details">
                <p className="note">{item.content}</p>
              </div>
            </div>
            <div className="image-view">
              <img
                alt=""
                src={imageURL}
                onLoad={this._handleImageLoaded}
                className={classNames({ '-loaded': this.state.imageLoaded })}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  _handleImageLoaded = () => {
    this.setState({
      imageLoaded: true
    })
  }
}

export default Story
