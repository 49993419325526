import React from 'react'
import '../styles/secondary.scss'

const OurStory = () => (
  <div className="default secondary">
    <h1>Our Story</h1>
    <p>Something goes here...</p>
  </div>
)

export default OurStory
