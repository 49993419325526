import React, { Component } from 'react'
import { connect } from 'react-redux'
import { checkToken, resetPassword } from '../modules/reset-password'
import '../styles/support.scss'

class ResetPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tokenLoaded: false,
      token: '',
      loadingMessage: 'Loading...',
      newPass: '',
      confirmPass: '',
      message: {
        type: '',
        text: ''
      }
    }
  }

  componentDidMount() {
    // Check the token
    this.props.onCheckToken(this.props.match.params.id)
  }

  componentDidUpdate(newProps) {
    if (this.props !== newProps) {
      this.checkStatus()
    }
  }

  render() {
    const { message } = this.state

    return (
      <div className="default support">
        <div className="reset-pass">
          {this.state.loadingMessage && (
            <div className="state-loading">
              <p>{this.state.loadingMessage}</p>
            </div>
          )}

          {!this.props.resetPassword.loading && this.props.resetPassword.valid && (
            <div className="state-success">
              <h3>Reset your password</h3>
              <div className="input-view">
                <form onSubmit={this._submitForm}>
                  <input
                    type="password"
                    placeholder="New password"
                    value={this.state.newPass}
                    onChange={this._handlePassChange}
                  />
                  <input
                    type="password"
                    placeholder="Confirm your password"
                    value={this.state.confirmPass}
                    onChange={this._handleConfirmPassChange}
                  />
                  <button type="submit">Submit</button>
                </form>
              </div>

              {message.text && (
                <div className="message-view">
                  <p className={message.type}>{message.text}</p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    )
  }

  checkStatus = () => {
    const { resetPassword } = this.props

    // Done loading but not valid
    if (resetPassword.loading === false && resetPassword.valid === false) {
      this.setState({
        loadingMessage:
          'This link is no longer valid. Redirecting you to the support page...'
      })

      // Redirect to /support
      setTimeout(() => {
        this.props.history.push('/support')
      }, 2000)
      return
    }

    // Token is valid
    if (resetPassword.valid === true) {
      this.setState({
        loadingMessage: null
      })

      // If password has been reset
      if (resetPassword.completed === true) {
        this._showMessage(
          'success',
          'Success! Your new password is ready to use :)'
        )
      } else if (resetPassword.completed === false) {
        this._showMessage(
          'error',
          'Your password could not be reset. Please try again.'
        )
      }
    }
  }

  _handlePassChange = e => {
    this.setState({ newPass: e.target.value })
  }

  _handleConfirmPassChange = e => {
    this.setState({ confirmPass: e.target.value })
  }

  _submitForm = e => {
    const { newPass, confirmPass } = this.state

    e.preventDefault()

    // Validate
    if (!newPass) {
      this._showMessage('error', 'Please enter a new password.')
      return
    } else if (!confirmPass) {
      this._showMessage('error', 'Please enter a confirm password.')
      return
    } else if (newPass !== confirmPass) {
      this._showMessage('error', 'Your passwords do not match :(')
      return
    }

    // Make API call
    this.props.onResetPassword(this.props.match.params.id, newPass)
  }

  _showMessage = (type, text) => {
    this.setState({
      emailAddress: '',
      message: {
        type,
        text
      }
    })
  }
}

const mapStateToProps = state => {
  return {
    resetPassword: state.resetPassword
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onCheckToken: token => {
      dispatch(checkToken(token))
    },
    onResetPassword: (token, password) => {
      dispatch(resetPassword(token, password))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPassword)
