import axios from 'axios'

export const GET_MESSAGE = 'GET_MESSAGE'

const initialState = {
  loading: true,
  data: undefined
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_MESSAGE:
      return {
        loading: false,
        data: action.payload
      }

    default:
      return state
  }
}

export const getMessage = id => {
  return dispatch => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/message/${id}`)
      .then(response => {
        dispatch(collectMessage(response.data[0]))
      })
      .catch(error => {
        dispatch(collectMessage(undefined))
      })
  }
}

export const collectMessage = data => {
  return {
    type: GET_MESSAGE,
    payload: data
  }
}
